import { SvgIcon } from '@mui/material'

export default function bath({ scale = 1, ...props }) {
  return (
    <SvgIcon transform={`scale(${scale})`} {...props}>
      <path
        fill={props.color || 'black'}
        d="M21 10H7V7C7 5.897 7.897 5 9 5C10.103 5 11 5.897 11 7H13C13 4.794 11.206 3 9 3C6.794 3 5 4.794 5 7V10H3C2.73478 10 2.48043 10.1054 2.29289 10.2929C2.10536 10.4804 2 10.7348 2 11V13C2 15.606 3.674 17.823 6 18.65V22H8V19H16V22H18V18.65C20.326 17.823 22 15.606 22 13V11C22 10.7348 21.8946 10.4804 21.7071 10.2929C21.5196 10.1054 21.2652 10 21 10ZM20 13C20 15.206 18.206 17 16 17H8C5.794 17 4 15.206 4 13V12H20V13Z"
      />
    </SvgIcon>
  )
}
