import { SvgIcon } from '@mui/material'

export default function icon(props) {
  return (
    <SvgIcon {...props} fill="none" width="20" height="20" viewBox="0 0 20 20">
      <path
        d="M9.65007 0.34285C9.84448 0.152377 10.1555 0.152377 10.3499 0.34285L11.921 1.88212C12.0459 2.00458 12.2259 2.05279 12.3953 2.00923L14.5255 1.4617C14.7891 1.39395 15.0585 1.54947 15.1316 1.81162L15.7225 3.93018C15.7695 4.09874 15.9013 4.23045 16.0698 4.27746L18.1884 4.86838C18.4505 4.9415 18.6061 5.21087 18.5383 5.47447L17.9908 7.60465C17.9472 7.77413 17.9954 7.95405 18.1179 8.07905L19.6572 9.65007C19.8476 9.84448 19.8476 10.1555 19.6572 10.3499L18.1179 11.921C17.9954 12.0459 17.9472 12.2259 17.9908 12.3953L18.5383 14.5255C18.6061 14.7891 18.4505 15.0585 18.1884 15.1316L16.0698 15.7225C15.9013 15.7695 15.7695 15.9013 15.7225 16.0698L15.1316 18.1884C15.0585 18.4505 14.7891 18.6061 14.5255 18.5383L12.3953 17.9908C12.2259 17.9472 12.0459 17.9954 11.921 18.1179L10.3499 19.6572C10.1555 19.8476 9.84448 19.8476 9.65007 19.6572L8.07905 18.1179C7.95405 17.9954 7.77413 17.9472 7.60465 17.9908L5.47447 18.5383C5.21087 18.6061 4.9415 18.4505 4.86838 18.1884L4.27746 16.0698C4.23045 15.9013 4.09874 15.7695 3.93018 15.7225L1.81163 15.1316C1.54947 15.0585 1.39395 14.7891 1.4617 14.5255L2.00923 12.3953C2.05279 12.2259 2.00458 12.0459 1.88212 11.921L0.34285 10.3499C0.152377 10.1555 0.152377 9.84448 0.34285 9.65007L1.88212 8.07905C2.00458 7.95405 2.05279 7.77413 2.00923 7.60465L1.4617 5.47447C1.39395 5.21087 1.54947 4.9415 1.81162 4.86838L3.93018 4.27746C4.09874 4.23045 4.23045 4.09874 4.27746 3.93018L4.86838 1.81163C4.9415 1.54947 5.21087 1.39395 5.47447 1.4617L7.60465 2.00923C7.77413 2.05279 7.95405 2.00458 8.07905 1.88212L9.65007 0.34285Z"
        fill="#19B683"
      />
      <path
        d="M6 10.5L8.64922 12.7077C8.84789 12.8732 9.14001 12.86 9.32287 12.6771L14.5 7.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        fill="#19B683"
      />
    </SvgIcon>
  )
}
