export default function share(props) {
  return (
    <svg
      width={props.size || 16}
      height={props.size || 16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5892 7.41082L9.9225 0.744156C9.80597 0.627612 9.6575 0.54824 9.49586 0.51608C9.33422 0.48392 9.16667 0.500415 9.01441 0.563479C8.86214 0.626544 8.732 0.733345 8.64044 0.870377C8.54887 1.00741 8.5 1.16851 8.5 1.33332V4.28742C6.22358 4.49813 4.10771 5.55071 2.56644 7.23919C1.02517 8.92766 0.169425 11.1305 0.166668 13.4167V14.6667C0.166655 14.8397 0.220523 15.0085 0.320795 15.1495C0.421067 15.2906 0.562767 15.3969 0.726224 15.4538C0.889681 15.5107 1.06678 15.5152 1.23295 15.4668C1.39911 15.4185 1.54609 15.3195 1.65348 15.1838C2.46986 14.2129 3.47153 13.4145 4.59997 12.8351C5.72842 12.2557 6.96102 11.907 8.22575 11.8094C8.26725 11.8041 8.37142 11.796 8.5 11.7878V14.6667C8.5 14.8315 8.54887 14.9926 8.64044 15.1296C8.732 15.2666 8.86214 15.3734 9.01441 15.4365C9.16667 15.4996 9.33422 15.5161 9.49586 15.4839C9.6575 15.4517 9.80597 15.3724 9.9225 15.2558L16.5892 8.58916C16.6666 8.5118 16.728 8.41995 16.7698 8.31886C16.8117 8.21777 16.8333 8.10941 16.8333 7.99999C16.8333 7.89056 16.8117 7.78221 16.7698 7.68112C16.728 7.58003 16.6666 7.48818 16.5892 7.41082ZM10.1667 12.6549V10.9167C10.1667 10.8072 10.1452 10.6988 10.1033 10.5977C10.0614 10.4966 10 10.4047 9.92265 10.3273C9.84527 10.2499 9.75339 10.1886 9.65227 10.1467C9.55115 10.1048 9.44278 10.0833 9.33333 10.0833C9.12093 10.0833 8.25342 10.1244 8.03207 10.1542C5.78589 10.361 3.6478 11.2144 1.87647 12.611C2.07741 10.7729 2.94932 9.07358 4.32522 7.83838C5.70113 6.60317 7.48432 5.91892 9.33333 5.91666C9.44278 5.91668 9.55115 5.89515 9.65227 5.85328C9.75339 5.81141 9.84527 5.75003 9.92265 5.67264C10 5.59525 10.0614 5.50338 10.1033 5.40226C10.1452 5.30114 10.1667 5.19277 10.1667 5.08332V3.34504L14.8216 7.99999L10.1667 12.6549Z"
        fill={props.color || '#050A22'}
      />
    </svg>
  )
}
