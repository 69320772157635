import { SvgIcon } from '@mui/material'

export default function bath(props) {
  return (
    <SvgIcon {...props} fill="none" viewBox="0 0 10 12">
      <path
        d="M11.1005 5.49995C11.1005 2.683 8.81691 0.399414 5.99995 0.399414C3.183 0.399414 0.899414 2.683 0.899414 5.49995C0.899414 7.58868 2.15504 9.38394 3.95259 10.1726C4.31378 10.3311 4.6617 10.5268 4.9406 10.8057L5.43427 11.2994C5.74669 11.6118 6.25322 11.6118 6.56564 11.2994L7.0593 10.8057C7.3382 10.5268 7.68613 10.3311 8.04732 10.1726C9.84486 9.38394 11.1005 7.58868 11.1005 5.49995ZM5.99995 3.77096C6.95485 3.77096 7.72894 4.54506 7.72894 5.49995C7.72894 6.45485 6.95485 7.22894 5.99995 7.22894C5.04506 7.22894 4.27096 6.45485 4.27096 5.49995C4.27096 4.54506 5.04506 3.77096 5.99995 3.77096Z"
        fill={props.color || '#fff'}
      />
    </SvgIcon>
  )
}
