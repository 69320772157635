import { currentSessionFavorites, userInfoCatch, loginDialogOpen } from '@/lib/catch'
import { statsigLog } from '@/lib/statsigAndGa'
import { useMutation, useReactiveVar } from '@apollo/client'
import { cloneDeep, isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { FAVORITE_PROPERTY } from '@/graphqls/mutations'
import { showLoginDrawer } from '@/state/login'

const usePropertyFavor = (propertyId: string | number, defaultFavor: boolean) => {
  const [favor, setStateFavor] = useState<boolean>(defaultFavor)
  const sessionFavorites = useReactiveVar<Map<string, boolean>>(currentSessionFavorites)
  const [favoriteProperty, { loading: favoriteLoading }] = useMutation(FAVORITE_PROPERTY)
  const { name: userName } = useReactiveVar(userInfoCatch)
  const setFavor = (f: boolean) => {
    if (isEqual(sessionFavorites.get(String(propertyId)), f)) {
      return
    }
    const tempSessionFavorites = cloneDeep(sessionFavorites)
    tempSessionFavorites.set(String(propertyId), f)
    currentSessionFavorites(tempSessionFavorites)
    setStateFavor(f)
  }

  const requestFavor = async (id: string | number, isFavor: boolean, isMobile = false) => {
    statsigLog('favorite_property_click', {
      property_id: propertyId,
      status: isFavor
    })
    if (favoriteLoading) {
      return
    }
    if (!userName) {
      if (isMobile) {
        showLoginDrawer(true)
      } else {
        loginDialogOpen(true)
      }
      return
    }
    try {
      await favoriteProperty({
        variables: {
          isFavor,
          propertyId: Number(propertyId)
        }
      })
      setFavor(isFavor)
    } catch (error) {
      // favor error
    }
  }
  useEffect(() => {
    if (sessionFavorites.has(String(propertyId))) {
      setStateFavor(sessionFavorites.get(String(propertyId)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionFavorites])
  return { favor, setFavor, loading: favoriteLoading, requestFavor }
}

export default usePropertyFavor
