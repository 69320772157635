import isFinite from 'lodash/isFinite'

const getCurrency = (value, fractionDigits = null) => {
  let numberValue = parseFloat(value)
  // if value isn't valid， then return a empty string
  if (!isFinite(numberValue) || Number.isNaN(numberValue)) {
    return ''
  }
  // Base on the existed logic, add  a param to fix fraction digits
  if (
    !Number.isNaN(parseInt(fractionDigits, 10)) &&
    !(!fractionDigits && typeof fractionDigits === 'object')
  ) {
    numberValue = numberValue.toFixed(parseInt(fractionDigits, 10))
  }

  return numberValue.toLocaleString('en-US')
}

const calcMinMaxPrice = leaseData => {
  if (!leaseData || !leaseData.length) {
    return null
  }

  const mins = []
  const maxs = []
  leaseData.forEach(({ minPrice: minValue, maxPrice: maxValue }) => {
    if (isFinite(parseInt(minValue, 10))) {
      mins.push(parseInt(minValue, 10))
    }
    if (isFinite(parseInt(maxValue, 10))) {
      maxs.push(parseInt(maxValue, 10))
    }
  })
  return [Math.ceil(Math.min.apply(null, mins)), Math.ceil(Math.max.apply(null, maxs))]
}

const getOmitPrice = value => {
  const num = parseFloat(value)
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1)}k`
  }
  return num.toString()
}

export { getCurrency, calcMinMaxPrice, getOmitPrice }
