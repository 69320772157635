import { SvgIcon } from '@mui/material'

export default function bed({ scale = 1, ...props }) {
  return (
    <SvgIcon transform={`scale(${scale})`} {...props}>
      <path
        d="M21 10.55V6C21 4.61927 19.8807 3.5 18.5 3.5H5.5C4.1193 3.5 3 4.61925 3 6V10.55C1.85889 10.7816 1 11.7905 1 13V17.5C1 18.0523 1.44772 18.5 2 18.5V20C2 20.5523 2.44772 21 3 21C3.55228 21 4 20.5523 4 20V18.5H20V20C20 20.5523 20.4477 21 21 21C21.5523 21 22 20.5523 22 20V18.5C22.5523 18.5 23 18.0523 23 17.5V13C23 11.7905 22.1411 10.7816 21 10.55ZM5.5 5.5H18.5C18.7762 5.5 19 5.72383 19 6V10.5H17C17 9.11927 15.8807 8 14.5 8H9.5C8.11927 8 7 9.11927 7 10.5H5V6C5 5.72385 5.22385 5.5 5.5 5.5ZM21 16.5H3V13C3 12.7238 3.22385 12.5 3.5 12.5H20.5C20.7762 12.5 21 12.7238 21 13V16.5ZM15 10.5H9C9 10.2238 9.22383 10 9.5 10H14.5C14.7762 10 15 10.2238 15 10.5Z"
        fill={props.color || 'white'}
      />
    </SvgIcon>
  )
}
